<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <!-- v-else -->
        <b-card v-else>
            <template v-slot:header>
                <h5>Tipe Barang</h5>
            </template>
            <div class="search-wrapper d-flex align-items-center justify-content-between">
                <b-form-group label="Filter" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
                    <div class="d-flex search-input-wrapper">
                        <b-form-input class="ml-2 mr-2" v-model="filter.tipe" name="filterTable" id="filterInput"
                            @keyup="getTipe()" type="search" placeholder="Cari Tipe"></b-form-input>
                        <b-form-select class="ml-2 mr-2" id="filterInput" v-model="filter.golongan" name="filterJenis">
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Pilih Golongan --</b-form-select-option>
                            </template>
                            <b-form-select-option v-for="jenis in jenisBarang" :value="jenis.id">
                                {{ jenis.text }}
                            </b-form-select-option>
                        </b-form-select>
                        <b-button class="mr-2" variant="pgiBtn" name="searchTableBtn" :disabled="isBusy" @click="onFilter">
                            <i class="fa fa-search"></i>
                        </b-button>
                        <b-button variant="pgiBtn" name="resetTableBtn" :disabled="isBusy"
                            @click="onReset()">Reset</b-button>
                    </div>
                </b-form-group>
                <div>
                    <b-button class="mb-3 mr-2" variant="pgiBtn" name="kategoriAddBtn"
                        @click="$bvModal.show('m-create')">Input Tipe Barang
                    </b-button>
                </div>
            </div>
            <b-table bordered hover show-empty :busy="isBusy" :items="tipeBarang" :fields="fields" :perPage="perPage"
                responsive class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data jenis barang.
                </template>
                <template v-slot:cell(action)="row">
                    <div class="no-wrap">
                        <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Edit"
                            name="kategoriEditBtn"
                            @click="showModalEdit(`${row.item.id}`, `${row.item.tipeBarang}`, `${row.item.idMerk}`, `${row.item.idJenis}`, `${row.item.kategori}`, `${row.item.satuan}`, `${row.item.harga}`, `${row.item.kodeBarang}`, `${row.item.tipeKode}`)">
                            <i class="fa fa-pencil-square-o"></i>
                        </b-button>

                        <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Delete"
                            name="jenisBarangHapusBtn" @click="showModalHapus(`${row.item.id}`)">
                            <i class="fa fa-trash text-danger"></i>
                        </b-button>
                    </div>
                </template>
            </b-table>

            <div class="pagination-wrapper d-flex align-items-center justify-content-between">
                <span class="ml-2">{{ totalViewText }}</span>
                <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table" @input="onPageChange"></b-pagination>
            </div>
        </b-card>


        <!-- MODAL CREATE -->
        <b-modal id="m-create" size="lg" title="Formulir Input Tipe Barang" no-close-on-backdrop no-close-on-esc
            hide-footer>
            <b-row>
                <b-col>
                    <b-form-group id="input-group-3" label="Jenis Barang" label-for="jenis barang">
                        <b-form-select id="jenis barang" v-model="idJenisInput" required>
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Pilih Jenis Barang
                                    --</b-form-select-option>
                            </template>
                            <b-form-select-option v-for="jenis in jenisBarang" :value="jenis.id">
                                {{ jenis.jenis }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group id="input-group-3" label="Merk Barang" label-for="merk-barang">
                        <b-form-select id="merk-barang" v-model="formInput.id_merk" required>
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Pilih Merk Barang
                                    --</b-form-select-option>
                            </template>
                            <b-form-select-option v-for="merk in merkBarang" :value="merk.id">
                                {{ merk.merk }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group id="input-tipe" label="Tipe Barang" label-for="input-tipe">
                        <b-form-input id="input-tipe" v-model="formInput.tipe" type="text" required></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group id="input-satuan" label="Satuan" label-for="input-satuan">
                        <b-form-input id="input-satuan" v-model="formInput.satuan" type="text" required></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Harga" label-for="harga">
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-input-group-text>Rp</b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input id="harga" v-model="formInput.harga" type="number"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Tipe Kode" for="tipe-kode">
                        <b-form-input id="tipe-kode" v-model="formInput.tipe_kode" type="text"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Kode Barang" for="kode-barang">
                        <b-form-input id="kode-barang" v-model="formInput.kode_barang" type="text"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>

            <div class="mt-4 text-right">
                <b-button variant="pgiBtn" @click="prepareInput('m-confirm-create')">
                    Simpan
                </b-button>
            </div>
        </b-modal>

        <ModalConfirm id="m-confirm-create" title="Confirm Tambah Tipe Barang" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk menambah Tipe Barang ini ?" @submit="submitInput" />

        <!-- MODAL EDIT -->
        <b-modal id="m-edit" size="lg" title="Edit Tipe" no-close-on-backdrop no-close-on-esc hide-footer>
            <b-row>
                <b-col>
                    <b-form-group id="input-group-3" label="Jenis Barang" label-for="jenis barang">
                        <b-form-select id="jenis barang" v-model="idJenis" required>
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Pilih Jenis Barang
                                    --</b-form-select-option>
                            </template>
                            <b-form-select-option v-for="jenis in jenisBarang" :value="jenis.id">
                                {{ jenis.jenis }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group id="input-group-3" label="Merk Barang" label-for="merk-barang">
                        <b-form-select id="merk-barang" v-model="form.id_merk" required>
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Pilih Merk Barang
                                    --</b-form-select-option>
                            </template>
                            <b-form-select-option v-for="merk in merkBarang" :value="merk.id">
                                {{ merk.merk }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group id="input-tipe" label="Tipe Barang" label-for="input-tipe">
                        <b-form-input id="input-tipe" v-model="form.tipe" type="text" required></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group id="input-satuan" label="Satuan" label-for="input-satuan">
                        <b-form-input id="input-satuan" v-model="form.satuan" type="text" required></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Harga" label-for="harga">
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-input-group-text>Rp</b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input id="harga" v-model="form.harga" type="number"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Tipe Kode" for="tipe-kode">
                        <b-form-input id="tipe-kode" v-model="form.tipe_kode" type="text"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Kode Barang" for="kode-barang">
                        <b-form-input id="kode-barang" v-model="form.kode_barang" type="text"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>

            <div class="mt-4 text-right">
                <b-button variant="pgiBtn" @click="prepare('m-confirm-edit')">
                    Simpan
                </b-button>
            </div>
        </b-modal>

        <ModalConfirm id="m-confirm-edit" title="Confirm Edit Tipe" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk mengubah Tipe Barang ini ?" @submit="submitEdit" />

        <ModalConfirm id="m-confirm-hapus" title="Confirm Hapus Tipe barang" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk menghapus Tipe barang ini ?" @submit="hapus" />
    </div>
</template>

<script>
export default {
    name: 'master-tipe-barang',
    data() {
        return {
            initStatus: 0,
            isBusy: true,
            isSubmitting: false,
            activeId: null,
            input: {
                show: 10
            },

            filter: {
                tipe: null,
                golongan: null,
            },

            formInput: {
                tipe: "",
                satuan: "",
                harga: null,
                tipe_kode: null,
                kode_barang: "",
                id_merk: null
            },

            form: {
                tipe: "",
                satuan: "",
                harga: null,
                tipe_kode: null,
                kode_barang: "",
                id_merk: null
            },

            idJenisInput: null,
            idJenis: null,

            fields: [
                { key: "tipeBarang", label: "Tipe Barang" },
                { key: "merkBarang", label: "Merk Barang" },
                { key: "jenisBarang", label: "Jenis Barang" },
                { key: "kategori", label: "Kategori" },
                { key: "satuan", label: "Satuan" },
                { key: "harga", label: "Harga Acuan" },
                { key: "kodeBarang", label: "Kode Barang" },
                { key: "action", label: "Aksi" },
            ],
            options: [
                { value: 10, text: '10' },
                { value: 20, text: '20' },
                { value: 30, text: '30' },
                { value: 40, text: '40' },
                { value: 50, text: '50' },
            ],
            tipeBarangAll: [],

            jenisBarang: [],
            merkBarang: [],
            tipeBarang: [],

            currentPage: 1,
            perPage: null,
            rows: null,
            totalViewText: "",
            page: '',
            size: '',
            from: '',
            limit: '',
            loading: false,
            query: "",

            //****SEMENTARA barang jenis null 
            null: [{
                id: null,
                jenis: "null",
                golongan: "null"
            }],
        }
    },

    methods: {

        //----------------- Fungsi Inisialisasi -----------------

        init() {
            console.log("ke init");
            this.initStatus = 0
            this.page = 1
            this.query = "?page=" + this.page

            let getJenis = this.$axios.get("api/admin/barang-jenis/all").then(response => {
                let datas = response.data.data
                console.log(datas);
                for (var data of datas) {
                    this.jenisBarang.push({
                        id: data.id,
                        jenis: data.jenis
                    })
                }
            })

            console.log(this.jenisBarang)

            let getTipe = this.$axios.get("api/admin/barang-tipe").then(response => {
                let datas = response.data.data
                console.log(datas.data);

                for (var data of datas.data) {
                    //****SEMENTARA barang jenis null 
                    if (data.barang_merk == null) {

                        this.tipeBarang.push({
                            id: data.id,
                            tipeBarang: data.tipe,
                            idMerk: this.null[0].jenis,
                            merkBarang: this.null[0].jenis,
                            idJenis: this.null[0].jenis,
                            jenisBarang: this.null[0].jenis,
                            kategori: this.null[0].jenis,
                            satuan: data.satuan,
                            harga: data.harga,
                            kodeBarang: data.kode_barang,
                            tipeKode: data.tipe_kode,
                        })
                    }


                    else {
                        console.log("ada merk");
                        // let golongan = this.jenisBarang.findIndex((item) => item.id == data.merk.id_jenis)
                        // console.log(golongan);
                        console.log(data.barang_merk.barang_jenis[0]);
                        if (data.barang_merk.barang_jenis[0] == undefined) {
                            console.log("ada merk tidak ada jenis", data.id);
                            this.tipeBarang.push({
                                id: data.id,
                                tipeBarang: data.tipe,
                                idMerk: data.barang_merk.id,
                                merkBarang: data.barang_merk.merk,
                                idJenis: this.null[0].jenis,
                                jenisBarang: this.null[0].jenis,
                                kategori: this.null[0].jenis,
                                satuan: data.satuan,
                                harga: data.harga,
                                kodeBarang: data.kode_barang,
                                tipeKode: data.tipe_kode,
                            })
                        }
                        else {
                            console.log("ada merk ada jenis", data.id);
                            this.tipeBarang.push({
                                id: data.id,
                                tipeBarang: data.tipe,
                                idMerk: data.barang_merk.id,
                                merkBarang: data.barang_merk.merk,
                                idJenis: data.barang_merk.id_jenis,
                                jenisBarang: data.barang_merk.barang_jenis[0].jenis,
                                kategori: data.barang_merk.barang_jenis[0].golongan,
                                satuan: data.satuan,
                                harga: data.harga,
                                kodeBarang: data.kode_barang,
                                tipeKode: data.tipe_kode,
                            })
                        }
                    }

                    //console.log(this.merkBarang)
                }


                // for (var data of datas.data) {

                //     //console.log(this.merkBarang)
                // }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to

                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)

            })

            Promise.all([getJenis, getTipe]).then(() => {
                this.initStatus = 1
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                    this.toggleBusy()
                })
        },
        getTipe() {
            this.toggleBusy()
            this.tipeBarang = []

            if (this.filter.tipe != null) this.query = this.query + "&tipe=" + this.filter.tipe
            this.$axios.get("api/admin/barang-tipe" + this.query).then(response => {
                let datas = response.data.data

                for (var data of datas.data) {
                    //****SEMENTARA barang jenis null 
                    if (data.barang_merk == null) {

                        this.tipeBarang.push({
                            id: data.id,
                            tipeBarang: data.tipe,
                            idMerk: this.null[0].jenis,
                            merkBarang: this.null[0].jenis,
                            idJenis: this.null[0].jenis,
                            jenisBarang: this.null[0].jenis,
                            kategori: this.null[0].jenis,
                            satuan: data.satuan,
                            harga: data.harga,
                            kodeBarang: data.kode_barang,
                            tipeKode: data.tipe_kode,
                        })
                    }


                    else {
                        if (data.barang_merk.barang_jenis[0] == undefined) {
                            this.tipeBarang.push({
                                id: data.id,
                                tipeBarang: data.tipe,
                                idMerk: data.barang_merk.id,
                                merkBarang: data.barang_merk.merk,
                                idJenis: this.null[0].jenis,
                                jenisBarang: this.null[0].jenis,
                                kategori: this.null[0].jenis,
                                satuan: data.satuan,
                                harga: data.harga,
                                kodeBarang: data.kode_barang,
                                tipeKode: data.tipe_kode,
                            })
                        }
                        else {
                            this.tipeBarang.push({
                                id: data.id,
                                tipeBarang: data.tipe,
                                idMerk: data.barang_merk.id,
                                merkBarang: data.barang_merk.merk,
                                idJenis: data.barang_merk.id_jenis,
                                jenisBarang: data.barang_merk.barang_jenis[0].jenis,
                                kategori: data.barang_merk.barang_jenis[0].golongan,
                                satuan: data.satuan,
                                harga: data.harga,
                                kodeBarang: data.kode_barang,
                                tipeKode: data.tipe_kode,
                            })
                        }
                    }
                }
                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to
                this.loading = false

                this.toggleBusy()
                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)

            })
                .catch(error => {
                    console.log(error)
                    console.log(error.response.data.reason)
                    this.$helper.parseError(this, error)
                    this.toggleBusy()
                    this.loading = false
                })
        },

        //-----------------  Fungsi Input ----------------- 
        prepareInput(modalToShow) {

            if (this.formInput.tipe == "" || this.formInput.tipe == null || this.formInput.tipe == "null") {
                return this.$helper.toastErr(this, "Mohon mengisi Tipe Barang!")
            }
            if (this.formInput.id_merk == null || this.formInput.id_merk == "" || this.formInput.id_merk == "null") {
                return this.$helper.toastErr(this, "Mohon mengisi Merk Barang!")
            }
            if (this.formInput.satuan == null || this.formInput.satuan == "" || this.formInput.satuan == "null") {
                return this.$helper.toastErr(this, "Mohon mengisi Satuan!")
            }
            if (this.formInput.harga == null || this.formInput.harga == "" || this.formInput.harga == "null") {
                return this.$helper.toastErr(this, "Mohon mengisi Harga!")
            }
            if (this.formInput.harga < 0) {
                return this.$helper.toastErr(this, "Harga Tidak boleh minus!")
            }
            if (this.formInput.tipe_kode == null || this.formInput.tipe_kode == "" || this.formInput.tipe_kode == "null") {
                return this.$helper.toastErr(this, "Mohon mengisi Tipe Kode!")
            }
            if (this.formInput.kode_barang == null || this.formInput.kode_barang == "" || this.formInput.kode_barang == "null") {
                return this.$helper.toastErr(this, "Mohon mengisi Kode Barang!")
            }

            this.$bvModal.show(modalToShow)
        },

        submitInput() {
            this.isSubmitting = true
            this.formInput.id_jenis = this.idJenis
            this.$axios.post("/api/admin/barang-tipe", this.formInput).then(res => {

                this.$helper.toastSucc(this, "Tipe Barang Berhasil Ditambahkan")
                this.$bvModal.hide("m-confirm-create")
                this.$bvModal.hide("m-create")

                this.idJenisInput = null
                this.formInput = {
                    tipe: "",
                    satuan: "",
                    harga: null,
                    tipe_kode: null,
                    kode_barang: "",
                    id_merk: null
                }
                this.getTipe()

            })
                .catch(error => this.$helper.parseError(this, error))
                .finally(() => this.isSubmitting = false)

        },

        //-----------------  Fungsi Edit ----------------- 
        prepare(modalToShow) {
            console.log(this.form)

            if (this.form.tipe == "" || this.form.tipe == null || this.form.tipe == "null") {
                return this.$helper.toastErr(this, "Mohon mengisi Tipe Barang!")
            }
            if (this.form.id_merk == null || this.form.id_merk == "" || this.form.id_merk == "null") {
                return this.$helper.toastErr(this, "Mohon mengisi Merk Barang!")
            }
            if (this.form.satuan == null || this.form.satuan == "" || this.form.satuan == "null") {
                return this.$helper.toastErr(this, "Mohon mengisi Satuan!")
            }
            if (this.form.harga == null || this.form.harga == "" || this.form.harga == "null") {
                return this.$helper.toastErr(this, "Mohon mengisi Harga!")
            }
            if (this.form.harga < 0) {
                return this.$helper.toastErr(this, "Harga Tidak boleh minus!")
            }
            if (this.form.tipe_kode == null || this.form.tipe_kode == "" || this.form.tipe_kode == "null") {
                return this.$helper.toastErr(this, "Mohon mengisi Tipe Kode!")
            }
            if (this.form.kode_barang == null || this.form.kode_barang == "" || this.form.kode_barang == "null") {
                return this.$helper.toastErr(this, "Mohon mengisi Kode Barang!")
            }

            this.$bvModal.show(modalToShow)
        },

        showModalEdit(id, tipe, merk, jenis, kategori, satuan, harga, kodeBarang, tipeKode) {
            this.idJenis = jenis
            this.activeId = id
            this.form.tipe = tipe
            this.form.satuan = satuan,
                this.form.harga = harga,
                this.form.tipe_kode = tipeKode,
                this.form.kode_barang = kodeBarang,
                this.form.id_merk = merk
            // this.activeId = id
            // this.form.merk = merk
            // this.form.id_jenis = (jenis != "null") ? jenis : null

            this.$bvModal.show('m-edit')
        },


        submitEdit() {

            console.log(this.form);
            this.isSubmitting = true
            this.form.id_jenis = this.idJenis
            this.$axios.post("/api/admin/barang-tipe/" + this.activeId, this.form).then(res => {

                this.$helper.toastSucc(this, "Tipe Berhasil Diedit")
                this.$bvModal.hide("m-confirm-edit")
                this.$bvModal.hide("m-edit")

                this.form = {
                    tipe: "",
                    satuan: "",
                    harga: null,
                    tipe_kode: null,
                    kode_barang: "",
                    id_merk: null
                }
                this.getTipe()

            })
                .catch(error => this.$helper.parseError(this, error))
                .finally(() => this.isSubmitting = false)

        },


        //-----------------  Fungsi Hapus ----------------- 
        showModalHapus(id) {
            this.activeId = id
            this.$bvModal.show('m-confirm-hapus')
        },

        hapus() {
            this.$axios.delete("api/admin/barang-tipe/delete/" + this.activeId).then(response => {

                this.$helper.toastSucc(this, "Tipe Barang Berhasil Dihapus")
                this.$bvModal.hide("m-confirm-hapus")

                this.form = {
                    tipe: "",
                    satuan: "",
                    harga: null,
                    tipe_kode: null,
                    kode_barang: "",
                    id_merk: null
                }
                this.getTipe()
            })
        },

        //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

        onFilter() {
            this.page = 1
            this.currentPage = 1
            this.query = "?page=" + this.page
            this.loading = true
            this.getTipe()
        },

        onPageChange() {
            console.log("ke on page");
            if (this.loading == false) {
                console.log(this.currentPage);
                this.page = this.currentPage
                this.query = "?page=" + this.page

                this.getTipe()
            }
            console.log(this.query);
        },

        onReset() {
            this.filter = {
                tipe: null,
                golongan: null,
            },

                this.page = 1
            this.query = "?page=" + this.page
            this.currentPage = 1
            this.loading = true
            this.getTipe()
        },


        //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },

        updateTotalItem(total) {
            this.perPage = this.size
            this.rows = total
            if (this.limit > this.rows) limit = this.rows
            this.totalViewText = "Menampilkan " + this.from + " - " + this.limit + " dari " + this.rows + " entri"
        },

    },

    watch: {


        //----------------- Pantau Jenis Barang -----------------

        'idJenisInput': function (id) {
            console.log(" ke watch", id);
            this.merkBarang = []
            this.$axios.get("api/admin/barang-merk" + "?id_jenis=" + id).then(response => {
                let datas = response.data.data
                console.log(datas.data);
                for (var data of datas.data) {
                    this.merkBarang.push({
                        id: data.id,
                        merk: data.merk
                    })
                }
                if (this.isBusy == true) this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    console.log(error.response.data.reason)
                    this.$helper.parseError(this, error)
                    if (this.isBusy == true) this.toggleBusy()
                })
        },

        'idJenis': function (id) {
            console.log(" ke watch", id);
            this.merkBarang = []
            this.$axios.get("api/admin/barang-merk" + "?id_jenis=" + id).then(response => {
                let datas = response.data.data
                for (var data of datas.data) {
                    this.merkBarang.push({
                        id: data.id,
                        merk: data.merk
                    })
                }
                if (this.isBusy == true) this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    console.log(error.response.data.reason)
                    this.$helper.parseError(this, error)
                    if (this.isBusy == true) this.toggleBusy()
                })
        },
    },


    created() {
        this.init()
    }
}
</script>
